
import { ref, defineComponent, onMounted, computed, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import * as Yup from "yup";
import _ from "lodash";
import { useRouter } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";

interface ReservationDetails {
  reservaId: string;
  nombre: string;
  email: string;

  esCompartido: boolean | undefined | null;
  viajeIda: boolean | undefined | null;
  lugarIda: string | undefined | null;
  lugarIdaPersonalizado: boolean | undefined | null;
  fechaRetiro: string | Date;
  fechaDevolucion: string | Date;
  fechaIda: Date | string | undefined | null;
  fechaViajeIda: string;
  horaIda: Date | string | undefined | null;
  horaViajeIda: string;
  cuposIda: number | undefined | null;

  viajeVuelta: boolean | undefined | null;
  lugarVueltaPersonalizado: boolean | undefined | null;
  lugarVuelta: string | undefined | null;
  fechaVuelta: Date | undefined | null;
  fechaViajeVuelta: string;
  horaVuelta: Date | string | undefined | null;
  horaViajeVuelta: string;
  cuposVuelta: number | undefined | null;
  destinoViaje:string;
  centroCosto:string;
  centroCostoPrimario:string;
}

export default defineComponent({
  name: "dashboard",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const submitButton1 = ref<HTMLElement | null>(null);
    const reservationValidator = Yup.object().shape({
      //estacionRetiro: Yup.string().required("Es obligatorio").label("Lugar de retiro"),
      //estacionDevolucion: Yup.string().required("Es obligatorio").label("Lugar de devolución"),
      //fechaRetiro: Yup.date().required("Es obligatorio").label("Fecha de retiro"),
      //fechaDevolucion: Yup.date().required("Es obligatorio").label("Fecha de devolucion"),
      destinoViaje: Yup.string().required("Es obligatorio").label("Destino Viaje"),
      //puntos: Yup.number().required("Es obligatorio").label("Puntos"),
    });
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      obtenerSucursales();
      obtenerReserva(); 
    });
    const reservationDetails = ref<ReservationDetails>({
      reservaId: store.getters.currentReserva.reservaId,
      esCompartido: false,
      viajeIda: false,
      lugarIda: "",
      lugarIdaPersonalizado:false,
      fechaIda: null,
      fechaViajeIda: "",
      horaIda: null,
      horaViajeIda: "",
      cuposIda: null,
      viajeVuelta: false,
      lugarVuelta: "",
      lugarVueltaPersonalizado:false,
      fechaVuelta: null,
      fechaViajeVuelta: "",
      horaVuelta: null,
      horaViajeVuelta: "",
      cuposVuelta: 0,
      nombre: "",
      email: "",
      destinoViaje:"",
      centroCosto:"",
      centroCostoPrimario:"",
      fechaRetiro:moment().toDate(),
      fechaDevolucion:moment().toDate()
    });
    const saveChanges = () => {
      if (submitButton1.value) {
        reservationDetails.value.fechaIda = moment(
          reservationDetails.value.fechaViajeIda,
          "DD-MM-YYYY"
        ).toDate();
        reservationDetails.value.horaIda = moment(
          reservationDetails.value.horaViajeIda,
          "hh:mm"
        ).toDate();

        reservationDetails.value.fechaVuelta = moment(
          reservationDetails.value.fechaViajeVuelta,
          "DD-MM-YYYY"
        ).toDate();
        reservationDetails.value.horaVuelta = moment(
          reservationDetails.value.horaViajeVuelta,
          "hh:mm"
        ).toDate();


        submitButton1.value.setAttribute("data-kt-indicator", "on");
        store
          .dispatch(Actions.UPD_RESERVA_PERSONAL, reservationDetails.value)
          .then(() => {
            submitButton1.value?.removeAttribute("data-kt-indicator");
            router.push({ name: "reservation-confirm" });
            
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getReservaErrors);
            Swal.fire({
              text: store.getters.getReservaErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Intentar nuevamente!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }
    };
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    const currentReserva = computed(() => {
      return store.getters.currentReserva;
    });
    const sucursales = computed(() => {
      return store.getters.allSucursales.filter(c=>c.esLocacionEuropcar);
    });
    const destinos = computed(() => {
      let destinosLocaciones =  store.getters.allSucursales.filter(c=>c.esLocacionEuropcar==false);
      /*return _.chain(destinosLocaciones)
               .groupBy("codigoSucursal")
               .map((value, key) => ({ grupo: key, locales: value }))
               .value();*/
      return _.orderBy(_.chain(destinosLocaciones)
               .groupBy("codigoSucursal")
               .map((value, key) => ({ grupo: key, locales: value }))
               .value(), ["orden"], ["asc"]);

      
      /*return destinosLocaciones.reduce((group, destin) => {
        const { category } = destin.codigoSucursal;
        group[category] = group[category] ?? [];
        group[category].push(destin);
        return group;
      }, {});*/

    });
    const obtenerReserva = () => {
      store
        .dispatch(Actions.GET_RESERVA, store.getters.reservaId)
        .then(() => {
          loading.value = false;
          const reserva = store.getters.currentReserva;
          reservationDetails.value = {
            reservaId: reserva.reservaId,
            esCompartido: reserva.esCompartido,
            viajeIda: reserva.viajeIda,
            lugarIda: reserva.lugarIda,
            lugarIdaPersonalizado: reserva.lugarIdaPersonalizado,
            fechaIda: reserva.fechaIda,
            fechaRetiro: moment(reserva.fechaRetiro).toDate(),
            fechaDevolucion: moment(reserva.fechaDevolucion).toDate(),
            fechaViajeIda: reserva.fechaViajeIda?moment(reserva.fechaViajeIda).format("DD/MM/yyyy"):moment(reserva.fechaRetiro).format("DD/MM/yyyy"),
            horaIda: reserva.fechaViajeIda?moment(reserva.fechaViajeIda).format("HH:mm"):moment(reserva.fechaRetiro).format("HH:mm"),
            horaViajeIda: reserva.fechaViajeIda?moment(reserva.fechaViajeIda).format("HH:mm"):moment(reserva.fechaRetiro).format("HH:mm"),
            cuposIda: reserva.cuposIda,
            viajeVuelta: reserva.viajeVuelta,
            lugarVuelta: reserva.lugarVuelta,
            lugarVueltaPersonalizado: reserva.lugarVueltaPersonalizado,
            fechaVuelta: reserva.fechaVuelta,
            fechaViajeVuelta: reserva.fechaViajeVuelta?moment(reserva.fechaViajeVuelta).format("DD/MM/yyyy"):moment(reserva.fechaDevolucion).format("DD/MM/yyyy"),
            horaVuelta: reserva.fechaViajeVuelta?moment(reserva.fechaViajeVuelta).format("HH:mm"):moment(reserva.fechaDevolucion).format("HH:mm"),
            horaViajeVuelta: reserva.fechaViajeVuelta?moment(reserva.fechaViajeVuelta).format("HH:mm"):moment(reserva.fechaDevolucion).format("HH:mm"),
            cuposVuelta: reserva.cuposVuelta,
            nombre: reserva.nombre,
            email: reserva.email,
            destinoViaje:reserva.destinoViaje,
            centroCosto:reserva.centroCosto,
            centroCostoPrimario:currentUser.value.centroCosto
          };
         })
        .catch(() => {
          const [error] = Object.keys(store.getters.getReservaErrors);
        });
    };
    const obtenerSucursales = () => {
      store
        .dispatch(Actions.GET_SUCURSALES, currentUser.value.clientId)
        .then(() => {
          loading.value = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getCuentaErrors);
        });
    };
    const loading = ref(true);
    return {
      loading,
      currentUser,
      sucursales,
      submitButton1,
      saveChanges,
      reservationDetails,
      reservationValidator,
      currentReserva,destinos
    };
  },
});
